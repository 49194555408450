interface IStringIndex {
  [key: string]: any;
}

const EnvVars: IStringIndex = {
  dev: {
    REACT_APP_USERPROFILE_SERVICE: 'https://api.userprofile-dev.autodesk.com/userprofile',
    REACT_APP_USERPROFILE_UPLOAD_SVC: 'https://api.userprofile-dev.autodesk.com/userprofile',
    ADSK_LOG_SERVICE_INFO_URL: 'https://uilogs.profile-dev.autodesk.com/log/info',
    ADSK_LOG_SERVICE_ERROR_URL: 'https://uilogs.profile-dev.autodesk.com/log/error',
    ADSK_PROFILE_URL: 'https://profile-dev.autodesk.com/personalinfo',
    ADSK_AEM_URL: 'https://www-pt.autodesk.com/api/assets/cfp/',
    ADSK_PRIVACY_URL: 'https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement',
  },
  stg: {
    REACT_APP_USERPROFILE_SERVICE: 'https://api.userprofile-stg.autodesk.com/userprofile',
    REACT_APP_USERPROFILE_UPLOAD_SVC: 'https://api.userprofile-stg.autodesk.com/userprofile',
    ADSK_LOG_SERVICE_INFO_URL: 'https://uilogs.profile-stg.autodesk.com/log/info',
    ADSK_LOG_SERVICE_ERROR_URL: 'https://uilogs.profile-stg.autodesk.com/log/error',
    ADSK_PROFILE_URL: 'https://profile-stg.autodesk.com/personalinfo',
    ADSK_AEM_URL: 'https://www-pt.autodesk.com/api/assets/cfp/',
    ADSK_PRIVACY_URL: 'https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement',
  },
  prd: {
    REACT_APP_USERPROFILE_SERVICE: 'https://api.userprofile.autodesk.com/userprofile',
    REACT_APP_USERPROFILE_UPLOAD_SVC: 'https://api.userprofile.autodesk.com/userprofile',
    ADSK_LOG_SERVICE_INFO_URL: 'https://uilogs.profile.autodesk.com/log/info',
    ADSK_LOG_SERVICE_ERROR_URL: 'https://uilogs.profile.autodesk.com/log/error',
    ADSK_PROFILE_URL: 'https://profile.autodesk.com/personalinfo',
    ADSK_AEM_URL: 'https://www.autodesk.com/api/assets/cfp/',
    ADSK_PRIVACY_URL: 'https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement',
  },
};

export default EnvVars;
