/* istanbul ignore file */
import { gql } from '@apollo/client';

const GET_MFE_PROFILE_INFO = gql`
query GetUserProfile(
  $userProfileFilter: UserProfileInput!,
  $rolesFilter: RolesInput!,
  $subSegmentsFilter: SubSegmentsInput!,
) {
    userProfile(filter: $userProfileFilter) {
      profileImages {
        isImagePresent
        thumbnails {
          sizeX120
        }
      }
      personalInfo {
        basicInfo {
          firstName
          lastName
          screenName
        }
        professionalInfo {
          jobTitle
          company {
            companyName
          }
          additionalProfessionalInfo {
            roleId
            industryTaxonomyIds {
              industryGroupId
              segmentId
              subSegmentId
            }
          }
        }
      }
    }
    roles(filter: $rolesFilter) {
      roles {
        roleId
        roleName
      }
    }
    subSegments(filter: $subSegmentsFilter) {
      subSegments {
        segmentId
        subSegmentId
        subSegmentName
        industryGroupId
      }
    }
  }
`;

export default GET_MFE_PROFILE_INFO;
