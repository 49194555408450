import UILogger from './LogService';

interface ErrorDetails {
  message: string;
  stacktrace: string;
}

interface Log {
  message: string;
  info: string;
}

interface DynatraceWindow extends Window {
  dtrum?: {
    reportCustomError: (context: string, message: string, info: string) => void;
  };
}

declare let window: DynatraceWindow;

const frameLog = (error: Error, errorInfo?: string): Log => ({
  message: error?.message || 'Custom Error',
  info: errorInfo || 'No Stack trace attached',
});

const frameErrorDetails = (
  error: Error,
  errorInfo?: string
): { errorDetails: ErrorDetails[] } => {
  const errorDetails = {
    errorDetails: [
      {
        message: error?.message,
        stacktrace: errorInfo || '',
      },
    ],
  };
  return errorDetails;
};

const sendLogsToDynatrace = (error: Error, context: string, errorInfo?: string): void => {
  if (window.dtrum?.reportCustomError) {
    const err = frameLog(error, errorInfo);
    window.dtrum.reportCustomError(context, err.message, err.info);
  }
};

const sendLog = (error: Error, errorInfo: string, context: string): void => {
  const logInstance = UILogger.getInstance();
  if (logInstance) {
    const errDetails = frameErrorDetails(error, errorInfo);
    logInstance.sendErrorLog({ context, msg: errDetails });
  } else {
    sendLogsToDynatrace(error, context, errorInfo);
  }
};

const sendCustomLog = (context: string, msg: string): void => {
  const logInstance = UILogger.getInstance();

  if (logInstance) {
    logInstance.sendInfoLog({ context, msg });
  } else {
    sendLogsToDynatrace({ message: msg } as Error, context);
  }
};

//Same as sendCustomLog but with object as parameter
const sendInfoLog = (context: string, obj: Object): void => {
  const logInstance = UILogger.getInstance();

  if (logInstance) {
    logInstance.sendInfoLog({ context, msg: obj });
  } else {
    sendLogsToDynatrace({ message: JSON.stringify(obj) } as Error, context);
  }
};

export { sendLog, sendCustomLog, sendInfoLog };
