import useAnalytics from '../hooks/useAnalytics';

export type TypePerformanceTiming = {
  appId: string;
};

const markSet = new Set<string>();
const { performance } = window;

export const performanceMetrics = ({ appId }: TypePerformanceTiming) => {
  const { logEventObj } = useAnalytics({ appId });

  const startMark = (markName: string) => {
    performance.mark(markName);
    markSet.add(markName);
  };

  const endMark = (markName: string) => {
    performance.mark(markName);
    markSet.add(markName);
  };

  const measureAndLog = (name: string, startMark: string, endMark: string) => {
    if (!markSet.has(startMark) || !markSet.has(endMark)) {
      return;
    }

    performance.measure(name, startMark, endMark);
    const measures = performance.getEntriesByName(name);
    const duration = Math.round(measures[0].duration);
    logEventObj({ performance: { name, duration } });
    return duration;
  };

  const clearMarks = (marks: Array<string>) => {
    marks.forEach((mark) => {
      performance.clearMarks(mark);
      markSet.delete(mark);
    });
  };

  const clearMeasures = (measures: Array<string>) => {
    measures.forEach((measure) => {
      performance.clearMeasures(measure);
    });
  };

  return { startMark, endMark, measureAndLog, clearMarks, clearMeasures };
};
