import React from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import typePolicies from '../helpers/typePolicies';

const ApolloClientProvider = ({
  children,
  uploadUrl,
  httpUrl,
  traceId,
  connectToDevTools,
  token,
}) => {
  const AuthLink = setContext(async () => {
    return {
      headers: {
        authorization: token,
        'x-amzn-trace-id': traceId,
      },
    };
  });

  const httplink = ApolloLink.split(
    (operation) =>
      operation.operationName === 'UpdateProfileImage' ||
      operation.operationName === 'DeleteProfileImages',
    createUploadLink({
      uri: uploadUrl,
    }),
    new HttpLink({
      uri: httpUrl,
    })
  );

  const client = new ApolloClient({
    link: from([AuthLink, httplink]),
    cache: new InMemoryCache({ typePolicies }),
    credentials: 'omit', // dont pass cookies in header.
    connectToDevTools,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
