import { sendCustomLog, sendInfoLog } from '../helpers/logs/ProfileLogger';

interface InitOptions {
  appId?: string;
  context?: string;
}

interface UseAnalytics {
  logEvent: (msg?: string) => void;
  logEventObj: (obj?: Object) => void;
}

const useAnalytics = ({
  appId = 'no app id',
  context = 'Profile MFE',
}: InitOptions = {}): UseAnalytics => {
  const logEvent = (msg: string = 'Generic log event message') => {
    sendCustomLog(context, `${msg}, app ID: ${appId}`);
  };

  const logEventObj = (obj: object) => {
    sendInfoLog(context, { ...obj, appId });
  };

  return { logEvent, logEventObj };
};

export default useAnalytics;
