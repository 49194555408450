import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Link from '@mui/material/Link';
import GET_MFE_PROFILE_INFO from '../queries/getMFEProfileInfo';
import {
  UPDATE_MFE_PROFILE_WITH_COMPANY,
  UPDATE_MFE_PROFILE_WITHOUT_COMPANY,
} from '../queries/updateMFEProfileInfo';
import { useQuery, useMutation } from '@apollo/client';

import getUserIdFromToken from '../helpers/getUserIdFromToken';

import {
  matchObjectsByID,
  filteredArray,
  getSelectedRole,
  selectedIndustryIds,
} from '../helpers';
import useAnalytics from '../hooks/useAnalytics';
import ProfileMFEViewSelector from './ProfileMFEViewSelector';
import Header from './Header';
import Fields from './Fields';
import Footer from './Footer';
import ErrorAlert from './Error/ErrorAlert';
import ErrorComponent from './Error/ErrorComponent';
import { sendLog } from '../helpers/logs/ProfileLogger';
import HeaderSkeleton from './skeletons/HeaderSkeleton';
import FooterSkeleton from './skeletons/FooterSkeleton';
import ConditionalRender from './common/ConditionalRender';
import EnvVars from '../constants/env';
import { getSesLocale } from '../translation/localeMaps';
import { Environment } from '../types/app';

import {
  getCloudEvent,
  sendCloudEvent,
  CloudEventParams,
} from '../helpers/cloudEventsHelper';
import { MFEStates } from '../types/app';
import { performanceMetrics } from '../helpers/performanceMetrics';

type ErrorObjType = {
  title?: string;
  message?: string;
};

type ProfessionalInfo = {
  company?: string;
  roleId: string;
  jobTitle: string;
  industryTaxonomyIds: any[];
};

type ProfileMFELayoutProps = {
  env?: Environment;
  locale?: string;
  appId?: string;
  description?: string;
  saveText?: string;
  cancelText?: string;
  continueText?: string;
  token: string;
  successMessage?: string;
  successTitle?: string;
  linkText?: string;
  linkUrl?: string;
  errorObj?: ErrorObjType;
  title?: string;
  message?: string;
  isModalOpen?: boolean;
  isCompanyHidden?: boolean;
  cancelBtnStyle?: object;
  successBtnStyle?: object;
  modalProps?: object;
  industryProps?: object;
  roleProps?: object;
  companyProps?: object;
  variant?: string;
  onSave?: (data: string | object) => void;
  onClose?: () => void;
  skeletons?: React.ReactNode;
};

type RoleStateType = {
  roleId: string;
  roleName: string;
};

type IndustriesStateType = {
  industryGroupId: string;
  segmentId: string;
  subSegmentId: string;
  subSegmentName: string;
};

const ProfileMFELayout: React.FC<ProfileMFELayoutProps> = (
  props: ProfileMFELayoutProps
) => {
  const {
    env,
    locale,
    appId,
    description,
    saveText,
    cancelText,
    continueText,
    successMessage,
    successTitle,
    token,
    linkText,
    linkUrl,
    errorObj: { title, message } = {},
    isModalOpen,
    isCompanyHidden,
    cancelBtnStyle,
    successBtnStyle,
    modalProps,
    industryProps,
    roleProps,
    companyProps,
    variant,
    onSave,
    onClose,
  } = props;
  const { t } = useTranslation();
  const { logEvent } = useAnalytics({ appId });
  const [isOpen, setIsOpen] = useState<boolean>(isModalOpen);
  const [image, setImage] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [role, setRole] = useState<RoleStateType>({ roleId: '', roleName: '' });
  const [isRoleCompLoaded, setIsRoleCompLoaded] = useState<boolean>(false);

  const [industries, setIndustries] = useState<IndustriesStateType[]>([]);
  const [isIndustryCompLoaded, setIsIndustryCompLoaded] = useState<boolean>(false);

  const [success, setSuccess] = useState<boolean>(false);
  const [isErrorOnSave, setIsErrorOnSave] = useState<boolean>(false);
  const [areFieldsUpdated, setAreFieldsUpdated] = useState<boolean>(false);
  const [isDataParsed, setIsDataParsed] = useState<boolean>(false);
  const userId = getUserIdFromToken(token);
  const sesLocale = getSesLocale(locale);

  const { startMark, endMark, measureAndLog, clearMarks, clearMeasures } =
    performanceMetrics({ appId });

  const UPDATE_MFE_PROFILE = isCompanyHidden
    ? UPDATE_MFE_PROFILE_WITHOUT_COMPANY
    : UPDATE_MFE_PROFILE_WITH_COMPANY;

  const { loading, error, data } = useQuery(GET_MFE_PROFILE_INFO, {
    variables: {
      userProfileFilter: { id: userId },
      rolesFilter: { lang: sesLocale },
      subSegmentsFilter: { lang: sesLocale },
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    fetchPolicy: 'cache-first',
    onError: () => {
      sendLog(error, 'MFEGraphQLQuery', 'Profile MFE');
    },
  });

  const [updateItem, { error: mutationError }] = useMutation(UPDATE_MFE_PROFILE, {
    onError: () => {
      setAreFieldsUpdated(false);
      setIsErrorOnSave(true);
      setSuccess(false);
      sendLog(error, 'MFEGraphQLUpdate', 'Profile MFE');
    },
    onCompleted: () => {
      endMark('MFE-save-end');
      measureAndLog('MFE-on-save', 'MFE-save-start', 'MFE-save-end');
      clearMarks(['MFE-save-start', 'MFE-save-end']);
      clearMeasures(['MFE-on-save']);
      setAreFieldsUpdated(false);
      setIsErrorOnSave(false);
      setSuccess(true);
    },
  });

  useEffect(() => {
    if (!data?.userProfile || mutationError) return;

    const allRoles = data?.roles?.roles;
    const allSegments = data?.subSegments?.subSegments;

    const {
      userProfile: { personalInfo },
    } = data;

    logEvent('Profile MFE loaded');

    //capture render difference and log it
    endMark('MFE-render-end');
    measureAndLog('MFE-on-render', 'MFE-render-start', 'MFE-render-end');
    //get the total load duration when the page is ready to interact
    measureAndLog('MFE-on-load', 'MFE-mount-start', 'MFE-render-end');
    measureAndLog('MFE-hosted-app-load', 'MFE-hosted-app-start', 'MFE-render-end');

    clearMarks(['MFE-render-start', 'MFE-render-end']);
    clearMeasures(['MFE-on-render', 'MFE-on-load', 'MFE-hosted-app-load']);

    const onloadEvent: CloudEventParams = getCloudEvent({
      type: MFEStates.PROFILE_MFE_LOADED,
    });
    sendCloudEvent(onloadEvent);

    const selectedRoleId =
      personalInfo?.professionalInfo?.additionalProfessionalInfo?.roleId;
    const selectedJobTitle = personalInfo?.professionalInfo?.jobTitle;

    // we need to validate first if roleId exists, if not we will retrieve info from jobTitle.
    // we then map it with the roles query list to get the object of the selected role.
    const selectedRole = getSelectedRole(allRoles, selectedRoleId, selectedJobTitle);

    const seletedIndustriesById = selectedIndustryIds(
      personalInfo?.professionalInfo?.additionalProfessionalInfo?.industryTaxonomyIds
    );
    const selectedIndustries = matchObjectsByID(seletedIndustriesById, allSegments);

    // we need to remove unused properties of industry for saving it inside mutation.
    const filteredSelectedIndustries = filteredArray(selectedIndustries, [
      '__typename',
      'segmentName',
    ]);

    setImage(`${data?.userProfile?.profileImages?.thumbnails?.sizeX120}`);
    setCompany(personalInfo?.professionalInfo?.company?.companyName);
    setRole(selectedRole);
    setIndustries(filteredSelectedIndustries);
    setIsDataParsed(true);
  }, [data]);

  const handleSave = () => {
    if (areFieldsUpdated) {
      const { roleId, roleName } = role;

      let professionalInfo: ProfessionalInfo = {
        roleId: roleId,
        jobTitle: roleName,
        industryTaxonomyIds: industries,
      };

      if (!isCompanyHidden) {
        professionalInfo.company = company;
      }

      startMark('MFE-save-start');
      updateItem({
        variables: {
          input: {
            id: userId,
            professionalInfo: professionalInfo,
          },
        },
      });

      const cloudEventObj: CloudEventParams = getCloudEvent({
        type: MFEStates.PROFILE_MFE_SAVED,
        data: professionalInfo,
      });
      sendCloudEvent(cloudEventObj);

      if (typeof onSave === 'function') {
        onSave(cloudEventObj);
      }
    }

    logEvent('Profile MFE save button click');
  };

  const handleClose = () => {
    setIsOpen(false);
    logEvent('Profile MFE cancel button click');
    sendCloudEvent(getCloudEvent({ type: MFEStates.PROFILE_MFE_CLOSED }));

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const roleOnloadCallback = () => {
    setIsRoleCompLoaded(true);
  };

  const roleOnErrorCallback = () => {
    throw new Error('Role component failed to load');
  };

  const industryOnloadCallback = () => {
    setIsIndustryCompLoaded(true);
  };

  const industryOnErrorCallback = () => {
    throw new Error('Industry component failed to load');
  };

  const onValueChanged = (value: any, inputName: string) => {
    setAreFieldsUpdated(true);
    setIsErrorOnSave(false);

    switch (inputName) {
      case 'companyValue':
        setCompany(value);
        break;
      case 'roleValue':
        setRole(value);
        break;
      case 'industryValue':
        setIndustries(value);
        break;
    }
  };

  const { firstName, lastName } = data?.userProfile?.personalInfo?.basicInfo || {};

  const content = {
    welcomeMessage: t('WELCOME_MESSAGE', { firstName, lastName }),
    description: description || t('DESCRIPTION_TEXT'),
    saveText: saveText || t('SAVE_BUTTON'),
    cancelText: cancelText || t('CANCEL_BUTTON'),
    continueText: continueText || t('CONTINUE_BUTTON'),
    successMessage: successMessage || (
      <Trans defaults={t('SUCCESS_MESSAGE')}>
        <Link target="_blank" href={EnvVars[env].ADSK_PROFILE_URL} />
      </Trans>
    ),
    successTitle: successTitle || t('SUCCESS_TITLE'),
    linkText: linkText || t('PRIVACY_LINK_TEXT'),
    linkUrl: linkUrl || EnvVars[env].ADSK_PRIVACY_URL,
    industryLabel: t('INDUSTRY_LABEL'),
    roleLabel: t('ROLE_LABEL'),
    companyLabel: t('COMPANY_LABEL'),
    companyError: t('COMPANY_ERROR_UNSUPP_CHARS'),
  };

  // Along with data readiness, check if role and industry are loaded as well.
  const isRenderReady =
    !loading && isDataParsed && isRoleCompLoaded && isIndustryCompLoaded;

  if (error) {
    return (
      <ErrorComponent
        errorTitle={title}
        errorMessage={message || error.message}
        isModalOpen={isModalOpen}
        variant={variant}
        onClose={handleClose}
        buttonText={content.cancelText}
        appId={appId}
      />
    );
  }

  return (
    <ProfileMFEViewSelector
      variant={variant}
      isModalOpen={isOpen}
      onClose={handleClose}
      header={
        <ConditionalRender
          isLoading={!isRenderReady}
          skeletonComponent={<HeaderSkeleton />}
          component={<Header message={content.welcomeMessage} imgSrc={image} />}
        />
      }
      footer={
        <ConditionalRender
          isLoading={!isRenderReady}
          skeletonComponent={<FooterSkeleton />}
          component={
            <Footer
              handleSave={handleSave}
              handleCancel={handleClose}
              saveText={content.saveText}
              cancelText={content.cancelText}
              isSuccess={success}
              isReadyToSave={areFieldsUpdated}
              continueText={content.continueText}
              cancelBtnStyle={cancelBtnStyle}
              successBtnStyle={successBtnStyle}
            />
          }
        />
      }
      {...modalProps}
    >
      <>
        {isErrorOnSave && <ErrorAlert message={mutationError?.message} />}
        <Fields
          description={content.description}
          locale={sesLocale}
          isCompanyHidden={isCompanyHidden}
          companyValue={company}
          industryValue={selectedIndustryIds(industries)}
          roleValue={role}
          onValueChange={onValueChanged}
          isSuccess={success}
          successTitle={content.successTitle}
          successMessage={content.successMessage}
          linkText={content.linkText}
          linkUrl={content.linkUrl}
          industryLabel={content.industryLabel}
          roleLabel={content.roleLabel}
          companyLabel={content.companyLabel}
          industryProps={{
            ...industryProps,
            onLoadCallback: industryOnloadCallback,
            onErrorCallback: industryOnErrorCallback,
          }}
          roleProps={{
            ...roleProps,
            onLoadCallback: roleOnloadCallback,
            onErrorCallback: roleOnErrorCallback,
          }}
          companyProps={{
            ...companyProps,
            errorTranslations: { errorUnsuppChars: content.companyError },
          }}
          isLoading={!isRenderReady}
          isDataParsed={!loading && isDataParsed}
        />
      </>
    </ProfileMFEViewSelector>
  );
};

export default ProfileMFELayout;
